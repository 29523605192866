/*
|--------------------
|      NEWS
|--------------------
*/

#page-news-archive {
  .item-news {
    &:first-child {
      .elem {
        &:before {
          content: none;
        }
      }
    }

    .block-text {
      margin-left: 0 !important;
    }

    .elem {
      &:before {
        content: "";
        background-color: #000;
        height: 130px;
        display: block;
        width: 1px;
        position: absolute;
        top: -155px;
        left: 0;
        right: 50%;
        margin: auto;
        @include media-breakpoint-down(md) {
          right: 0;
        }
      }

      .bgn-img {
        position: relative;
        overflow: hidden;
        max-height: 370px;
        vertical-align: middle;
        align-items: center;
        display: flex;

      }

      &:before {
        //content: none;
      }

      h2 {
        padding: 0 0 15px 0;
        color: #222222;
        font-family: "Questa", serif !important;
        text-transform: initial;
        line-height: 24px;
      }

      .text {
        padding: 0px;
        width: 100% !important;
      }
    }

    &:first-child {
      .bgn-img {
        &:before {
          content: none;
        }
      }
    }
  }

  .btn.loadMoreBtn {
    margin: -110px auto 0 auto;
    display: block;
    width: 270px;
    color: #222222;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 24px;
    text-transform: uppercase;
    border: 1px solid #222222;
    height: 50px;
    max-width: 270px;
    background: white;
    transition: 0.3s all cubic-bezier(0.55, 0, 0.1, 1);
    text-align: center;

    &:hover {
      background: #222222;
      color: #ffffff;
      text-decoration: none;
    }
  }
}