/*
|--------------------
|      SINGLE
|--------------------
*/

#page-news-single {
  h1 {
    color: #222;
    font-family: "Questa", serif !important;
    font-size: 45px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  a.link {
    color: #222;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #222;
    height: 50px;
    max-width: 270px;
    width: 100%;
    background: #fff;
    line-height: 50px;
    transition: 0.3s all cubic-bezier(.55, 0, .1, 1);
    text-align: center;
    margin: auto;
    display: block;

    &:hover,
    &:focus {
      background: #222;
      color: #fff;
      text-decoration: none;
    }
  }

  .post-date {
    display: block;
    text-align: center;
    color: #444;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
  }

  .content-new {
    color: #444;
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;

    p {
      color: #444;
      font-family: "Montserrat", serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      margin: 0 0 8px;
    }

    ul, ol {
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  .control-news {
    display: inline-flex;

    a {

      &.d-fl {
        display: flex;
        &:hover{
          .new-prev {
            filter: invert(1);
          }
          .new-next {
            filter: invert(1);
          }
        }
      }

      .new-prev {
        background-image: url('../img/icon/navArrowLeft.jpg');
        height: 49px;
        width: 49px;
        background-repeat: no-repeat;
      }

      .new-next {
        background-image: url('../img/icon/navArrowRight.jpg');
        height: 49px;
        width: 49px;
        background-repeat: no-repeat;
      }
    }
  }

  .alignleft {
    float: left;
    margin: 0 15px 15px 0;
    height: auto;
  }

  .aligncenter {
    text-align: center;
    margin: 0 auto;
    display: block;
  }
}