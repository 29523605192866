/*
|--------------------
|     PAGE HOME
|--------------------
*/

.home {
  #header,
  #footer {
    display: none;
  }

  .menu-sites {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    background-color: #000;
    background-position: center;
    background-size: cover;
    transition: all ease 0.5s;
    z-index: 99999;

    a {
      position: relative;
      display: flex;
      vertical-align: middle;
      align-items: center;
      width: 33.33%;
      float: left;
      text-align: center;
      height: 100vh;
      transition: all ease 0.3s;
      background-size: cover;
      background-position: center;

      @include media-breakpoint-up(lg) {
        background: transparent !important;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        height: 33.33%;
      }

      &:before {
        content: "";
        background-color: #fff;
        position: absolute;
        right: 0;
        height: 100%;
        width: 1px;
        opacity: 0.2;
      }

      &:last-child {
        &:before {
          content: none;
        }
      }

      .elem {
        margin: auto;

        .elem-title {
          text-align: center;
          width: 100%;
          font-size: 45px;
          font-weight: 400;
          line-height: 40px;
          color: #fff;
          font-family: "Questa", serif !important;
        }

        .elem-link {
          color: #ffffff;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
          border: 1px solid rgba(255, 255, 255, 0.4);
          width: 100%;
          max-width: 170px;
          margin: 40px auto 0 auto;
          transition: all ease 0.3s;
          height: 0;
          padding: 0;
          opacity: 0;
          overflow: hidden;
          display: block;

          &:hover {
            background: rgba(255, 255, 255, 0.4);
            text-decoration: none;
          }

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }

      &:hover {
        text-decoration: none;
        transition: all ease 0.3s;

        .elem-link {
          transition: all ease 0.3s;
          height: initial;
          padding: 10px 0;
          opacity: 1;
        }
      }
    }
  }
}