/*
|--------------------
|      FOOTER
|--------------------
*/
#footer {
  padding: 50px 0;

  h3 {
    color: black;
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .ft-block {
    &:after {
      content: "";
      height: 1px;
      width: calc(100% - 30px);
      background-color: #d2d2d2;
      margin: 10px auto 0 auto;
    }

    .col {
      @include media-breakpoint-down(sm) {
        text-align: center;
      }

      a {
        display: block;
        color: black;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 5px;

        &.ft-phone {
          &:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url('../img/icon/phone.png') no-repeat center;
            position: relative;
            top: 5px;
            margin-right: 5px;
          }
        }

        &.ft-email {
          &:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url('../img/icon/mail.png') no-repeat center;
            position: relative;
            top: 5px;
            margin-right: 5px;
          }
        }

        &.ft-pc {
          &:before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url('../img/icon/pc.png') no-repeat center;
            position: relative;
            top: 5px;
            margin-right: 5px;
          }
        }

        &.rs {
          display: inline-block;

          img {
            height: 18px;
            width: 18px;
          }
        }

        &.addr {
          font-weight: 400;
          line-height: 18px;
        }

        &.btn-acces {
          width: 100%;
          display: flex;
          vertical-align: middle;
          align-items: center;
          position: relative;
          @include media-breakpoint-down(md) {
            height: 37px;
          }
          @include media-breakpoint-down(sm) {
            margin: 0 auto 10px auto;
          }

          &:hover {
            color: #fff;

            span {
              &:after {
                content: "";
                filter: invert(100%);
              }
            }
          }

          span {
            margin: auto;
            text-transform: initial;
            position: relative;
            @include media-breakpoint-down(lg) {
              font-size: 10px;
            }

            &:after {
              content: "";
              display: inline-block;
              width: 7px;
              height: 18px;
              background: url('../img/icon/linkArrow.png') no-repeat center;
              position: relative;
              top: 5px;
              margin-left: 15px;
            }
          }
        }
      }
    }
  }

  .m-a {
    text-align: center;
    margin: auto;
  }
  .ft-logo {
    margin: 30px auto 0 auto !important;
    display: block;


    img {
      max-height: 120px;
      max-width: 260px;
    }
  }

  .copyright {
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    font-weight: 300;
    line-height: 25px;
    margin-top: 25px;
    text-align: center;
  }

  .ft-newsletter {
    .gform_heading {
      display: none;
    }

    form {
      text-align: center;
      margin: auto;
      display: flex;
      width: 270px;

      .gform_body {
        border: none;

        input[type=email] {
          width: 100%;
        }

        .gfield_error {
          padding: 0;
          margin: 6px;
        }
      }

      .gform_footer {
        clear: inherit;
        width: auto;

        input[type=submit] {
          color: #222222;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 700;
          line-height: 24px;
          text-transform: uppercase;
          background: none;
          height: inherit;
          position: relative;
          top: 0px;
          left: 0;
          padding: 3px 10px;
          border: none;
          cursor: pointer;
        }
      }

      .ginput_container_email {
        input {
          padding: 11px 9px;
          letter-spacing: normal;
          width: 200px;
          border: 1px solid;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 300;

          &::placeholder {
            color: black;
          }
        }
      }

      .gform_legacy_markup_wrapper ul.gform_fields li.gfield {
        padding-right: 0 !important;
      }
    }
  }

}