/*
|--------------------
|      CONTENT
|--------------------
*/

/*
|
| Banner
|---------
*/
.banner {
  @extend .bg-cover;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 410px;
  background-color: $black;

  .filtre {
    position: absolute;
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .item-content {
    width: 100%;
    padding: 100px 0;
  }
}


/*
|
| Custom card
|
| Use in news archive page
|--------------
*/
.custom-card {

  .custom-card-link {
    display: block;
  }

  /*
  * Card img
  */
  .card-img-container {
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    .card-img {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: transform 2s $easeOutExpo;
      will-change: transform;
    }
  }

  /*
  * Card content
  */
  .card-content {

    .date {
      color: rgba($very-dark-grey, 0.6);
    }
  }

  /*
  * Hover state
  */
  &:hover {

    .card-img-container {

      .card-img {
        transform: scale(1.05);
      }
    }
  }


}


/*
|
| Cookie banner
|----------------
*/
.cookie-banner {
  display: none !important;
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;

  .item-close {
    font-size: 24px;
    cursor: pointer;
  }


  @include media-breakpoint-down(sm) {
    padding: 20px 0;
    font-size: 12px;
  }

  @include media-breakpoint-down(xs) {
    padding: 10px 0 20px 0;

    .item-close {
      font-size: 18px;
    }
  }
}


/*
|
| Pagination
|---------------
*/
.pagination-container {
  li {
    display: inline-block;

    a {
      transition: all 0.3s ease-out;
      padding: 0 8px;

      &:hover {
        color: $grey;
      }
    }

    .current {
      padding: 0 8px;
      font-weight: 800;
      color: $grey;
    }
  }
}


/*
|
| CMS
|------
*/
.cms {
  span {
    display: inline;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 30px;
  }

  h1 {
    @extend .title-xxl;
  }

  h2 {
    color: #444;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
  }

  h3 {
    @extend .title-lg;
  }

  h4 {
    @extend .title-md;
  }

  h5 {
    @extend .title-sm;
  }

  h6 {
    @extend .title-xs;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  strong {
    font-weight: 800;
  }

  p {
    font-family: "Montserrat", serif;
    color: #444444 !important;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 15px;
  }


  blockquote {
    position: relative;

    width: 80%;

    margin-right: 0;
    margin-left: auto;
    padding: 130px 0;

    font-size: 30px;
    line-height: 1.5em;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      margin: auto;
    }

    &:before {
      content: "";

      position: absolute;

      background-image: url('../img/icon/quote-left.svg');

      width: 50px;
      height: 50px;


      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(-60px) translateY(-30px);
    }

    &:after {
      content: "";

      position: absolute;
      right: 0;

      background-image: url('../img/icon/quote-right.svg');

      width: 50px;
      height: 50px;


      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;

      transform: translateX(0) translateY(-50px);

      @include media-breakpoint-down(md) {
        transform: translateX(60px) translateY(-50px);
      }
    }
  }
}


/*
|
| Page offset
|--------------
*/
.page-offset {
  padding-top: $headerHeight;

  @include media-breakpoint-down(lg) {
    padding-top: $headerHeightSm;
  }
}


/*
|
| Custom Loader
|---------------
*/
.custom-loader {
  width: 100px;
  display: inline-block;

  &.cl-center {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    display: inline-block;
    width: 100%;
    height: auto;
    fill: $black;

    circle {
      stroke: $black;
    }
  }

  &.ajax-loader {
    display: none;
  }
}

.link {
  color: #222222;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  border: 1px solid #222222;
  height: 50px;
  max-width: 270px;
  width: 100%;
  background: white;
  display: inline-block;
  line-height: 50px;
  margin-bottom: 5px;
  transition: 0.3s all cubic-bezier(0.55, 0, 0.1, 1);
  text-align: center;

  &:hover, &:focus {
    background: #222222;
    color: #ffffff;
    text-decoration: none;
  }

}

.intro,
.section_flex_title {
  h1 {
    color: #222222;
    font-family: "Questa", serif !important;
    font-size: 45px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 8px;
  }

  .bg-intro {
    height: 682px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;
    display: block;
    @include media-breakpoint-down(lg) {
      height: 590px;
    }
    @include media-breakpoint-down(md) {
      height: initial;
      background-image: none !important;
    }

    .block-intro {
      height: 682px;
      @include media-breakpoint-down(lg) {
        height: 590px;
      }
      @include media-breakpoint-down(md) {
        height: initial;
      }

      .txt-intro {
        color: #444444;
        font-family: "Montserrat", serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        text-align: right;
        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      .btn-intro {
        float: right;
        @include media-breakpoint-down(md) {
          float: inherit;
          margin: auto;
          display: block;
        }
      }

      img {
        max-width: 100%;
        @include media-breakpoint-down(md) {
          margin: auto;
          display: block;
        }
      }
    }
  }
}

#page-classic {
  .banner {
    text-align: center;
    color: #fff;
  }
}

#page-home {
  .slider-home {
    .swiper-home {
      overflow: hidden;
      position: relative;

      .swiper-slide {
        overflow: hidden;

        img {
          width: 100%;
        }

        .details {
          position: absolute;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          padding: 15px;
          width: 60%;
          margin: 15px;
          @include media-breakpoint-down(lg) {
            width: 90%;
          }
          @include media-breakpoint-down(md) {
            width: 75%;
          }

          h2 {
            color: #fff;
            font-family: "Montserrat", serif;
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 24px;
          }

          .desc {
            color: #fff;
            font-family: "Montserrat", serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .galerie_title {
      color: #444;
      font-family: "Montserrat", serif;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 24px;
    }

    .galerie_desc {
      color: #444;
      font-family: "Montserrat", serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 29px;

      h2 {
        color: #444;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: -10px;
        margin-bottom: 20px;
      }
    }

    .swiper-button {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 110px;
      height: 70px;

      .swiper-button-prev {
        background-color: #fff;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 26px;
        left: 0;

        &:after {
          content: "";
          background-image: url('../img/icon/owlPrev.jpg');
          height: 40px;
          width: 40px;
        }
      }

      .swiper-button-next {
        position: absolute;
        background-color: #000;
        left: inherit;
        right: 0;
        top: 39px;
        width: 70px;

        &:after {
          content: "";
          background-image: url('../img/icon/owlNext.jpg');
          height: 70px;
          width: 70px;
        }
      }
    }

  }

  .double {
    color: #444444;
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    margin-top: 80px;
    @include media-breakpoint-down(md) {
      margin: 30px auto;
    }

    img {
      width: 100%;
      z-index: 1;
      position: relative;
      @include media-breakpoint-down(md) {
        margin: 15px 0;
      }
    }

    .double-title {
      font-weight: 700;
      text-transform: uppercase;
    }

    .link {
      @include media-breakpoint-down(md) {
        margin: auto;
        display: block;
      }
    }

    .item-dec {
      position: relative;

      &:before {
        content: "";
        background-image: url('../img/icon/lineArrow.png');
        height: 112px;
        width: 7px;
        display: block;
        position: absolute;
        top: -70px;
        left: 0;
        right: 0;
        margin: auto;
        @include media-breakpoint-down(md) {
          content: none;
        }
      }
    }
  }

  .triple {
    &:before {
      content: "";
      background-image: url('../img/icon/bottomHome.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      display: block;
      height: 112px;
      position: absolute;
      top: -27px;
      left: 0;
      right: 0;
      margin: auto;
      @include media-breakpoint-down(lg) {
        top: -10px;
      }
      @include media-breakpoint-down(md) {
        content: "";
        display: none;
      }
    }

    .triple-elem {
      &:before {
        content: "";
        width: 17px;
        height: 17px;
        background-color: #ffffff;
        border: 1px solid black;
        margin: 0 auto;
        display: block;
        border-radius: 50%;
        margin-top: 43px;
        margin-bottom: 45px;
        transition: 0.2s all cubic-bezier(0.55, 0, 0.1, 1);
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:hover {
        &:before {
          content: "+";
          width: 17px;
          height: 17px;
          background-color: #222222;
          border: 1px solid black;
          color: #ffffff;
          margin: 0 auto;
          display: block;
          border-radius: 50%;
          margin-top: 43px;
          margin-bottom: 45px;
          line-height: 12px;
          text-align: center;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }

      .triple-title {
        color: #444444;
        font-family: "Montserrat", serif;
        font-size: 18px;
        line-height: 24px;
        margin-top: 80px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .triple-img {
        height: 260px;
        width: 100%;
        overflow: hidden;
        position: relative;

        span {
          background-size: cover;
          background-position: center;
          transform: scale(1.15);
          height: 260px;
          position: absolute;
          left: 0;
          right: 0;
          transition: 0.6s all cubic-bezier(0.55, 0, 0.1, 1);
        }

        &:hover {
          span {
            content: "";
            width: 100%;
            transform: scale(1);
            transition: 0.6s all cubic-bezier(0.55, 0, 0.1, 1);
          }
        }
      }

      .swiper-actus {
        overflow: hidden;

        .swiper-slide {
          .img {
            height: 220px;
            background-size: cover;
            background-position: center;
            width: inherit;
            margin: 0 auto;
          }

          .date {
            font-family: "Montserrat", sans-serif;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-transform: uppercase;
          }

          .title {
            font-family: "Montserrat", serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 19px;
            text-align: left;
            text-transform: uppercase;
          }
        }

        .swiper-button {
          margin: auto;
          display: flex;
          height: 26px;
          width: 100px;
          position: relative;

          .swiper-button-prev {
            left: 0;
            position: absolute;

            &:after {
              content: "";
              font-size: 16px;
              color: #000;
              border: 1px solid;
              width: 27px;
              height: 27px;
              background-image: url('../img/icon/navArrowLeft.jpg');
              background-size: contain;
            }
          }

          .swiper-button-next {
            right: 0;
            position: absolute;

            &:after {
              content: "";
              font-size: 16px;
              color: #000;
              border: 1px solid;
              width: 27px;
              height: 27px;
              background-image: url('../img/icon/navArrowRight.jpg');
              background-size: contain;
            }
          }
        }
      }
    }

  }

  .block-intro {
    position: relative;
  }
}


.section_flex_editor,
.section_flex_editor p {
  color: #444;
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
}

.section_flex_gallery {
  #swiper-container {
    height: 550px;
    width: 100%;
    overflow: hidden;
    margin: 0 0 80px 0;

    .swiper-slide {
      display: flex;
      height: 500px;

      img {
        display: block;
        max-width: max-content;
        height: auto;
        margin: auto;
      }

      .legend {
        display: block;
        position: absolute;
        top: initial;
        bottom: -50px;
        width: 100%;
        transition: 0.3s all cubic-bezier(0.55, 0, 0.1, 1);
        color: #999999;
        font-family: "Montserrat", serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 30px;
        padding: 0;
        margin-top: 10px;

        &:before {
          content: "";
          height: 1px;
          width: 100%;
          background-color: #999;
          display: block;
          position: absolute;
          bottom: 12px;
          z-index: -1;
        }

        span {
          background-color: #fff;
          display: inline-block;
          padding: 0 22px 0 0;
          z-index: 2;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    top: initial;
    bottom: 16px;
    left: initial;
    right: 77px;
    height: 49px;
    width: 49px;
    background-image: url('../img/icon/navArrowLeft.jpg');

    &:after {
      content: none;
    }
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    top: inherit;
    bottom: 16px;
    right: 15px;
    height: 49px;
    width: 49px;
    background-image: url('../img/icon/navArrowRight.jpg');

    &:after {
      content: none;
    }
  }

  .col-lg-6 {
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev,
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      bottom: -44px;
    }
  }

}

.section_flex_slider {
  #swiper-container {
    width: 100%;
    overflow: hidden;
    margin: 0 0 20px 0;

    .swiper-slide {
      height: 90px;

      img {
        display: block;
        max-height: 90px;
        max-width: 160px !important;
        margin: auto;
        height: inherit;
        width: inherit;
      }
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    top: 50px;
    left: 0;
    right: inherit;
    height: 35px;
    width: 35px;
    background-size: cover;
    background-image: url('../img/icon/navArrowLeft.jpg');

    &:after {
      content: none;
    }
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    top: 50px;
    right: 0;
    height: 35px;
    width: 35px;
    background-size: cover;
    background-image: url('../img/icon/navArrowRight.jpg');

    &:after {
      content: none;
    }
  }
}

.section_flex_img_txt {
  padding-bottom: 15px;

  .elem {
    &:before {
      content: "";
      height: 1px;
      width: calc(100% - 30px);
      background: #d2d2d2;
      margin: auto;
    }

    h2 {
      color: #444;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0; 
      padding: 40px 0;
    }

    .block-text {
      margin-left: -90px;
      @include media-breakpoint-down(lg) {
        margin-left: 0px;
      }

      .text {
        margin-top: 30px;
        background: #fff;
        width: calc(100% + 90px);
        padding: 35px 40px 15px 40px;
        @include media-breakpoint-down(lg) { 
          width: 100%;
          padding: 0px; 
        }
      }
    }

    .bgn-img {
      img {
        width: 100%;
        max-width: 100%;
        display: block;
      }
    }
  }
}

.section_flex_cta {
  padding-bottom: 15px;

  .h500 {
    height: 500px;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }

  img {
    width: 50px;
    height: 50px;
    margin: 15px auto;
  }

  h2 {
    color: #fff;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 40px 0;
  }

  .link-cta {
    background: transparent;
    border-color: #fff !important;
    color: #fff;
    width: initial;
    max-width: inherit;
    padding: 0 55px;
    @include media-breakpoint-down(sm) {
      padding: 0 8px;
    }

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  .bg {
    height: 500px;
    display: block;
    text-align: center;
    background-repeat: repeat;
    background-size: cover;
  }
}

.section_flex_chrono {
  .chrono-thumbs {
    &:after {
      content: "";
      height: 1px;
      width: 110%;
      background-color: #000;
      display: block;
      position: absolute;
      top: 32px;
    }

    .swiper-slide {
      cursor: pointer;
      position: relative;
      padding-top: 20px;
      transition: all ease 0.3s;

      &:before {
        content: "";
        height: 5px;
        width: 5px;
        background-color: #000;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
      }

      &:hover, &.swiper-slide-active {
        color: #895c0e;
        transition: all ease 0.3s;
      }
    }

    .ch-year {
      display: block;
      font-family: "Montserrat", serif;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;
    }

    .ch-title {
      display: block;
      font-family: "Montserrat", serif;
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin-top: 30px;
    }

    .swiper-chrono-prev {
      background-image: url('../img/icon/navArrowLeft.jpg');
      height: 30px;
      width: 30px;
      display: block;
      position: absolute;
      left: 0;
      top: 17px;
      background-repeat: no-repeat;
      background-color: #fff;
      background-size: contain;
      z-index: 10;
      cursor: pointer;

      &:hover {
        filter: invert(1);
      }
    }

    .swiper-chrono-next {
      background-image: url('../img/icon/navArrowRight.jpg');
      height: 30px;
      width: 30px;
      display: block;
      position: absolute;
      right: 0;
      top: 17px;
      background-repeat: no-repeat;
      background-color: #fff;
      background-size: contain;
      z-index: 10;
      cursor: pointer;

      &:hover {
        filter: invert(1);
      }
    }
  }

  .chrono-content {
    .chrono-galerie {
      position: relative;
    }

    .details {
      box-sizing: border-box;

      .title-details {
        color: #444444;
        line-height: 30px;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .texte-details {
        color: #444444;
        font-family: "Montserrat", serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
      }
    }

    .swiper-button {
      position: absolute;
      bottom: 0px;
      right: 0;
      width: 110px;
      height: 70px;

      .swiper-button-prev {
        background-color: #fff;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 52px;
        left: 0px;
        right: initial;

        &:after {
          content: "";
          background-image: url('../img/icon/owlPrev.jpg');
          height: 40px;
          width: 40px;
        }
      }

      .swiper-button-next {
        position: absolute;
        background-color: #000;
        left: inherit;
        right: 0;
        top: 35px;
        width: 70px;

        &:after {
          content: "";
          background-image: url('../img/icon/owlNext.jpg');
          height: 70px;
          width: 70px;
        }
      }
    }

    hr {
      margin: 20px 0;
    }
  }

  p {
    color: #444444;
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }


  .texte-details {
    overflow: hidden;

    iframe {
      max-width: 100%;
    }

    section {
      border-bottom: 1px solid #d2d2d2;
      padding: 45px 0;
    }

    .padded {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 8px;
    }

    .credits p {
      color: #999999 !important;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }
  }

  .db {
    color: #999999 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    p, li {
      color: #999999 !important;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      margin: 0 0 10px;
    }
  }

  h2 {
    color: #444444;
    line-height: 30px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
  }

  .smallTxt {
    font-family: "Montserrat", serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 25px;
    color: #444444 !important;

    p {
      color: #444444 !important;
    }
  }

  .partenaires {
    .item-partenaires {
      text-align: center;
      height: 80px;
      margin: 10px 0;

      img {
        margin: auto;
        position: relative;
        top: 50%;
        max-width: 120px;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  section {
    border-bottom: 1px solid #d2d2d2;
    padding: 45px 0;
    width: 100%;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  .dbl-col {
    border-top: 1px solid #d2d2d2;
    margin-top: 60px;
  }
}


.section_flex_pres {

  .section_flex_pres_detail {
    .pres_title {
      color: #444444;
      line-height: 30px;
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .pres_sub_title {
      color: #444444;
      font-family: "Montserrat", serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }

    .pres_sub_texte, .pres_sub_texte p {
      color: #444444;
      font-family: "Montserrat", serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
    }

    img {
      margin: 20px 0 35px 0;
      max-width: 100%;
    }
  }

  .chrono-galerie {
    position: relative;

    .swiper-button {
      position: absolute;
      bottom: 35px;
      right: 0;
      width: 110px;
      height: 70px;

      .swiper-button-prev {
        background-color: #fff;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 52px;
        left: 0;

        &:after {
          content: "";
          background-image: url('../img/icon/owlPrev.jpg');
          height: 40px;
          width: 40px;
        }
      }

      .swiper-button-next {
        position: absolute;
        background-color: #000;
        left: inherit;
        right: 0;
        top: 35px;
        width: 70px;

        &:after {
          content: "";
          background-image: url('../img/icon/owlNext.jpg');
          height: 70px;
          width: 70px;
        }
      }
    }
  }
}

.page-template-restaurants {
  color: #444;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  h1 {
    color: #222222;
    font-family: "Questa", serif !important;
    font-size: 45px;
    font-weight: 400;
    line-height: 40px;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 8px;
  }

  h2 {
    color: #444;
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 800;
    line-height: 24px;
    margin-top: 32px;
    margin-bottom: 15px;
  }

  .block-img {
    img {
      max-width: -webkit-fill-available;
      margin: auto;
      display: block;
      @include media-breakpoint-down(md) {
        max-height: 400px;
      }
    }
  }

  .block-text {
    margin-left: -90px;
    @include media-breakpoint-down(md) {
      margin-left: 0px;
    }

    .text {
      font-family: "Montserrat", serif;
      font-size: 16px;
      color: #444444;
      margin-top: 30px;
      background: #fff;
      width: calc(100% + 90px);
      padding: 35px 40px 35px 40px;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding: 0;
      }

      h2 {
        margin-top: 0;
      }

      p {
        font-family: "Montserrat", serif;
        font-size: 15px;
        color: #444444;
      }
    }
  }

  p {
    color: #444;
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  .a {
    hyphens: auto;
    display: block;
  }

  .section_flex_gallery {
    #swiper-container {
      height: 440px;
      @include media-breakpoint-down(lg) {
        height: 380px;
      }
      @include media-breakpoint-down(md) {
        height: 400px;
      }
      @include media-breakpoint-down(sm) {
        height: 420px;
      }
      @include media-breakpoint-down(xs) {
        height: 320px;
      }

      .swiper-slide {
        height: fit-content !important;
      }

      img {
        display: block;
        max-width: -webkit-fill-available !important;
        height: auto;
        margin: auto;
      }

      .swiper-button-prev,
      .swiper-button-next {
        bottom: initial;
      }
    }
  }

}