/*
|--------------------
|      HEADER
|--------------------
*/

/*
|
| Header
|---------
*/
.link-menu {
  text-transform: uppercase;
  color: $very-dark-grey;
  font-size: 14px;
  font-weight: 400;
}

#header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease 0.3s;
  @include media-breakpoint-down(lg) {
    z-index: 10000;
    width: 230px;
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.5);
    transition: all ease 0.3s;

    .header-container {
      padding-top: 3px;
      padding-bottom: 3px;
      transition: all ease 0.3s;

      .item-logo {
        transition: all ease 0.3s;

        img {
          height: 40px !important;
          margin: 10px auto;
          transition: all ease 0.3s;
        }
      }
    }
  }

  .header-container {
    @extend .container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all ease 0.3s;
    @include media-breakpoint-down(lg) {
      .logo-main {
        margin: auto;
      }
    }

    .item-logo {
      width: 160px;
      display: block;
      transition: all ease 0.3s;

      img {
        height: 60px;
        transition: all ease 0.3s;
      }
    }

    .item-nav {
      display: none;

      .item-menu {
        display: flex;

        li {
          &:not(:last-child) {
            margin-right: 18px;
          }

          .item-link {
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 5px;
            color: #fff;
            margin: 10px 0;
            transition: all ease 0.3s;
            position: relative;

            &:before {
              content: "";
              height: 1px;
              width: 0%;
              background-color: #fff;
              bottom: -6px;
              position: absolute;
              transition: all ease 0.3s;
            }

            &:hover {
              transition: all ease 0.3s;
              text-decoration: none;

              &:before {
                content: "";
                width: 100%;
                transition: all ease 0.3s;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(xl) {
        display: flex;
        align-items: center;

        .item-menu {
          margin-right: 25px;
        }
      }
    }
  }

  .lang-container {
    position: relative;
    display: inline-flex;
    top: 1px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    color: #ffffff;
    text-transform: uppercase;

    span {
      font-family: "Montserrat", sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 23px;
      color: #ffffff;
      text-transform: uppercase;
    }

    ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      transform: translateX(10px);
      transition: opacity 1.2s $easeSmooth, transform 2s $easeSmooth;

      li {
        a {
          color: #fff;
        }
      }
    }

    &:hover {
      ul {
        opacity: 1 !important;
        visibility: visible !important;
        transform: translateX(0) !important;
      }
    }
  }

  .btn-menu {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //width: 56px;
    //height: 56px;
    margin-top: 5px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: none !important;

    & > .item-burger {
      display: block;
      width: 25px;

      & > span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $white;
        border-radius: 2px;

        &:nth-child(2) {
          margin: 6px 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

}

/*
|
| Menu
|-------
*/
#mobile-menu {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $white;
  overflow: auto;
  opacity: 0;
  visibility: hidden;

  .item-container {
    @extend .container;
    //padding-top: 150px;
    //padding-bottom: 50px;

    ul {
      & > li {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          text-transform: uppercase;
          color: $very-dark-grey;
          font-size: 30px;
          font-weight: 500;
          -webkit-font-smoothing: antialiased;

          @include media-breakpoint-down(xs) {
            font-size: 26px;
          }
        }
      }
    }
  }

  .item-socials-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .item-socials {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      a {
        font-size: 25px;

        &:not(:last-child) {
          margin-right: 25px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-bottom: 15px;

        a {
          font-size: 20px;
        }
      }
    }
  }
}


.mobmenu-left-panel .mobmenu-left-bt {
  right: inherit;
  left: 10px;
  top: 9px;
}

.mobmenu-panel {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .mobmenu-content {
    padding: 0 80px !important;
    margin: 0;
    @include media-breakpoint-down(lg) {
      padding: 0 50px !important;
    }
    @include media-breakpoint-down(md) {
      padding: 0 20px !important;
    }
    @include media-breakpoint-down(sm) {
      padding: 0px !important;
    }

    ul {
      li {
        clear: both;

        a {
          color: black !important;
          font-size: 34px !important;
          font-weight: 400 !important;
          line-height: 1 !important;
          padding-top: 40px;
          transition: all ease 0.1s;

          &:hover {
            color: #895c0e !important;
            background-color: initial !important;
            transition: all ease 0.1s;
          }

          .mob-expand-submenu {
            display: none !important;
          }
        }

        ul {
          display: block !important;
          padding: 0 15%;
          background-color: initial !important;

          li {
            padding-left: 0 !important;
            width: 50% !important;
            float: left;
            clear: inherit;
            @include media-breakpoint-down(md) {
              width: 100% !important;
            }

            a {
              padding-top: 8px !important;
              padding-bottom: 0 !important;
              padding-left: 30px !important;
              color: #222222 !important;
              font-size: 14px !important;
              font-weight: 700 !important;
              line-height: 20px !important;
              text-transform: uppercase !important;
            }
          }
        }

        &:hover {
          background-color: initial !important;
        }

        &:last-child {
          li:last-child {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}