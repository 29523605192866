/*
|
| Page Loader
|--------------
*/
.page-loader {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: $white;
  //display: none;
  display: flex;
  overflow: hidden;

  &.active {
    display: flex;
  }

  .item-content {
    width: 100%;
    color: $very-dark-grey;
    text-align: center;
  }

  .item-loadbar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    margin: auto;


    .item-loadbar-inner {
      width: 100%;
      height: 100%;
      border-radius: 1px;
      background-color: $very-dark-grey;
      animation: loadbar 1.2s cubic-bezier(.92, 0, .09, 1);
      transform-origin: left top;
    }
  }

  img {
    max-height: 160px;
  }
}

@keyframes loadbar {
  from {
    transform: scaleX(0)
  }
  to {
    transform: scaleX(0.7)
  }
}