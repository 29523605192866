/*
|--------------------
|       404
|--------------------
*/

#page-404 {

  /*
  |
  | Section contact
  |------------------
  */
  .section-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: $very-dark-grey;
    color: $white;
    text-align: center;
    font-size: 18px;
    font-family: "Montserrat", serif;
    font-weight: 400;
    line-height: initial;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .item-title {
      @extend .title-xxl;
      font-size: 100px;
      font-weight: 300;
      line-height: 1;
      @include media-breakpoint-down(md) {
        font-size: 75px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 48px;
      }
    }

    .btn {
      text-decoration: none !important;
    }
  }
}
