/*
|-----------------------
| 	  Gravity Form
|-----------------------
|
*/

/*
|
| Reset form elements
|-------------------------
*/
input, textarea, select{
    -webkit-appearance: none;
    border-radius: 0;

    &:focus{
        outline: none;
    }
}

select{
    background-image: url('../img/icon/bottom.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: calc(100% - 10px) center;
}

/*
|
| Gravity form bootstrap
|-------------------------
*/
.gravity-form-bootstrap{
    .gform_fields{
        @extend .row;

        input, textarea{
            width: 100%;
            background-color: transparent;
        }
    }
}

/*
|
| Materialize form 
|-------------------
*/
.materialize-form{
    .materialize-form-group{ 
        position: relative;
        margin-bottom: 25px;

        label{
            position: absolute;

            top: 0;
            left: 15px;

            font-weight: 400;
            font-size: 18px;
        }
        input{
            margin-bottom: 35px;

            padding: 7px 0;

            border: 0;
            border-bottom: 2px solid $light-grey;
        }

        &.gfield_error{
            label{
                top: -20px;
            }
        }
        
    }
}

/*
|
| Contact form 
|---------------
*/
.contact-form{
    label{
        color: $very-dark-grey;
    }

    textarea{
        border: 2px $light-grey solid;
        height: 200px;
        padding: 15px;
        color: $black;
        font-size: 18px;
        border-radius: 2px;

        &::placeholder{
            color: $black;
        }
    }

    .gform_body{
        margin-bottom: 20px;
    }

    .gform_footer {
        text-align: right;
    }

    input[type="submit"]{
        position: relative;
        background-color: transparent;
        color: $dark-grey;
        padding: 15px 30px;
        transition: all 0.3s ease-out;
        text-transform: uppercase;
        cursor: pointer;
        border: 2px solid $light-grey;
        border-radius: 2px;

        &:hover{
            color: $white;
            background: $light-grey;
        }

    }

    .gform_confirmation_message{
        color: $very-dark-grey;
    }

    .validation_message{
        margin-top: 5px;
        color: #BB0B0B;
        font-size: 14px;
    }
}


.validation_error{
    margin-bottom: 30px;
    color: #BB0B0B!important;
}

#gform_ajax_spinner_1 {
    //display: none;
    margin-left: 10px;
    display: inline-block;
    width: 50px;
    height: 50px;
}