/*
|--------------------------
|      DEFAULT & RESET
|--------------------------
*/
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: "Questa", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  @each $property, $value in $default-titles {
    #{$property}: $value;
  }
}

//em{
//  font-family: "Questa","Helvetica Neue",Helvetica,Arial,sans-serif;
//  font-size: 14px;
//  line-height: 1.42857;
//  color: #333;
//  background-color: #fff;
//}

.clearfix {
  display: block;
  clear: both;
}

.txtc {
  text-align: center;
}

.nopad {
  padding-left: 0;
  padding-right: 0;
}

.dflex {
  display: flex;
  vertical-align: middle;
  align-items: center;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.d-mobile {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.d-desktop {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

button, input, textarea {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

button {
  -webkit-appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
}

img {
  width: initial;
}

.txt-r {
  text-align: right;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.txt-l {
  text-align: left;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.txt-c{
  text-align: center;
}

.o-h{
  overflow: hidden;
}

.m-auto {
  margin: auto;
  display: block !important;
}

.separator {
  max-width: fit-content !important;
}

a, img, span, button {
  display: inline-block;
}

a {
  color: $default-link-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: $default-link-color;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

#ot-sdk-btn{
  color: initial!important;
  font-size: inherit!important;
  border: initial!important;
  padding: initial!important;
  &:hover{
    background-color:none!important;
    text-decoration:underline!important;
  }
}